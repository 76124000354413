<template>
  <div class="export-page">
    <CustomPageHeader
      title="Export"
      :hasDatePicker="false"
      :hasFilter="false"
      :hasFilterButton="false"
    >
    </CustomPageHeader>
    <div class="page-content" @scroll="onScroll">
      <a-tabs v-if="isMobileScreen" v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="Create New Export"></a-tab-pane>
        <a-tab-pane key="2" tab="Export History"></a-tab-pane>
      </a-tabs>

      <div class="content-wrapper">
        <div class="export-content">
          <div
            v-if="!isMobileScreen || (isMobileScreen && activeKey === `1`)"
            class="ant-col ant-col-xs-24 ant-col-lg-16 trigger-col"
            style="padding-left: 12.5px; padding-right: 12.5px"
          >
            <div class="filter-sidebar">
              <a-card
                :bordered="false"
                title="Create New Export"
                class="detail-card"
              >
                <div class="filter-content">
                  <div class="export-details">
                    <FeatherIcon type="share" />
                    <span class="title">Export Details</span>

                    <div class="detail-container">
                      <div class="filter">
                        <div class="detail-head">
                          Filename <span class="text-muted">(Optional)</span>
                        </div>
                        <a-input
                          v-model:value="file_name"
                          size="small"
                          placeholder="Enter a file name (Optional)"
                          class="detail-input"
                        />
                      </div>

                      <a-row :gutter="16">
                        <a-col :span="12">
                          <div class="detail-head">Export Type</div>
                          <RadioButton
                            v-model:value="export_type"
                            name="exportType"
                            :options="filteredExportTypeOptions"
                          ></RadioButton>
                        </a-col>

                        <a-col :span="12">
                          <div class="detail-head">File Type</div>
                          <RadioButton
                            v-model:value="export_file_type"
                            name="fileType"
                            :options="fileTypeOptions"
                          >
                          </RadioButton>
                        </a-col>
                      </a-row>
                    </div>
                  </div>

                  <a-divider />

                  <div class="filter-details">
                    <FeatherIcon type="filter" />
                    <span class="title">Filter Options</span>

                    <div class="filter-container">
                      <div class="filter">
                        <div class="detail-head">Date</div>
                        <DateRangeButton
                          class="location-drb"
                          style="flex: 1 1 auto"
                        />
                      </div>

                      <div class="filter">
                        <div class="detail-head">
                          Channels and Sources
                          <span class="text-muted"
                            >({{ helper.numeral(filter.source.length) }})</span
                          >
                        </div>
                        <ChanelsAndSources
                          @onChangeSource="onChangeSource"
                        ></ChanelsAndSources>
                      </div>

                      <div class="filter">
                        <div class="detail-head">
                          Category
                          <span class="text-muted">({{ categoryCount }})</span>
                        </div>
                        <Category
                          @onChangeCategory="onChangeCategory"
                        ></Category>
                      </div>

                      <!-- <div class="filter">
                        <div class="detail-head">Chanels and Sources</div>
                        <ExportTreeSelect
                          :showIcon="true"
                          name="source"
                          :treeData="sourceTreeDataComputed"
                          :selected="selectedSource"
                          @onChangeSource="onChangeSource"
                        ></ExportTreeSelect>
                      </div> -->

                      <!-- Advanced filter -->

                      <div class="advanced-filter-wrapper">
                        <a-row :gutter="16">
                          <a-col :span="12">
                            <div class="filter">
                              <div class="detail-head">
                                Type of Message
                                <span class="text-muted"
                                  >({{
                                    helper.numeral(filter.messageType.length)
                                  }})</span
                                >
                              </div>
                              <MessageType
                                @onChangeMessageType="onChangeMessageType"
                              ></MessageType></div
                          ></a-col>
                          <a-col :span="12">
                            <div class="filter">
                              <div class="detail-head">
                                Sentiment
                                <span class="text-muted"
                                  >({{
                                    helper.numeral(filter.sentiment.length)
                                  }})</span
                                >
                              </div>
                              <Sentiment
                                @onChangeSentiment="onChangeSentiment"
                              ></Sentiment>
                            </div>
                          </a-col>
                        </a-row>
                        <a-row :gutter="16">
                          <a-col :span="12">
                            <div class="filter">
                              <div class="detail-head">
                                Content Type
                                <span class="text-muted"
                                  >({{
                                    helper.numeral(filter.contentType.length)
                                  }})</span
                                >
                              </div>
                              <ContentType
                                @onChangeContentType="onChangeContentType"
                              ></ContentType>
                            </div>
                          </a-col>
                          <a-col :span="12">
                            <div class="filter">
                              <div class="detail-head">
                                Social Media Account
                                <span class="text-muted"
                                  >({{
                                    helper.numeral(filter.ownMedia.length)
                                  }})</span
                                >
                              </div>
                              <OwnMedia
                                @onChangeMedia="onChangeMedia"
                              ></OwnMedia>
                            </div>
                          </a-col>
                        </a-row>
                      </div>

                      <!-- <div
                        class="more-filter message-subcomment"
                        @click="isShowAdvancedFilter = !isShowAdvancedFilter"
                      >
                        <span v-if="!isShowAdvancedFilter">
                          Advanced Filter
                        </span>
                        <span v-if="isShowAdvancedFilter">
                          Hide Advanced Filter
                        </span>
                      </div> -->
                    </div>
                  </div>
                </div>

                <a-divider />

                <div class="filter-footer">
                  <a-button
                    :class="{
                      'btn-export': !disabledExportButton && !isGenerating,
                      'btn-export-mobile': isMobileScreen,
                    }"
                    :disabled="disabledExportButton || isGenerating"
                    @click="exportFile"
                  >
                    Export
                  </a-button>
                </div>
              </a-card>
            </div>
          </div>
          <div
            v-if="!isMobileScreen || (isMobileScreen && activeKey === `2`)"
            class="ant-col ant-col-lg-8"
            style="padding-left: 12.5px; padding-right: 12.5px; width: 100%"
          >
            <div class="export-table">
              <a-card
                :bordered="false"
                title="Export History"
                class="detail-card"
                :bodyStyle="{ 'padding-left': '0px', 'padding-right': '0px' }"
              >
                <div v-if="loading" class="load-wrapper">
                  <a-skeleton :loading="loading" active />
                </div>

                <div
                  v-if="
                    !loading &&
                    exportHistoryData &&
                    exportHistoryData.length > 0
                  "
                >
                  <ExportHistoryTable
                    v-if="exportHistoryData"
                    :isMobileScreen="isMobileScreen"
                    :current-page="currentPage"
                    :total-records="exportHistoryData.length"
                    :export-history-data="exportHistoryData"
                    @reload="reload"
                  ></ExportHistoryTable>
                </div>

                <div
                  v-if="
                    !loading &&
                    exportHistoryData &&
                    exportHistoryData.length <= 0
                  "
                >
                  <NoDataAspect :title="'No Export History Data'">
                  </NoDataAspect>
                </div>
              </a-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  onMounted,
  ref,
  onBeforeUnmount,
  onUnmounted,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import api from '@/services/api';
import helper from '@/services/helper';
import Category from './Filters/Category.vue';
import ChanelsAndSources from './Filters/ChanelsAndSources.vue';
import OwnMedia from './Filters/OwnMedia.vue';
import MessageType from './Filters/MessageType.vue';
import Sentiment from './Filters/Sentiment.vue';
import ContentType from './Filters/ContentType.vue';

import ExportHistoryTable from '@/components/Table/ExportHistoryTable.vue';
// import DateRangePickerButton from '@/components/Button/DateRangePickerButton.vue';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';

import NoDataAspect from '@/components//Error/NoDataAspect.vue';
import DateRangeButton from '@/components/Button/DateRangeButton.vue';
import RadioButton from '../../components/Button/RadioButton.vue';

const defaultExportTypeOptions = [
  { label: 'Report', value: 'report3.2' },
  // { label: 'Influencer', value: 'influencer' },
  { label: 'Raw data', value: 'raw' },
];

const defaultFileTypeOptions = [
  { label: 'CSV', value: 'csv', icon: 'csv' },
  { label: 'JSON', value: 'json', icon: 'json' },
];

const defaultReportFileTypeOptions = [
  { label: 'PDF', value: 'pdf', icon: 'pdf' },
];

export default {
  name: 'Export',
  components: {
    Category,
    ChanelsAndSources,
    OwnMedia,
    MessageType,
    Sentiment,
    ContentType,
    // DateRangePickerButton,
    ExportHistoryTable,
    CustomPageHeader,
    NoDataAspect,
    DateRangeButton,
    RadioButton,
  },
  setup() {
    const store = useStore();
    // let exportFilter = ref([]);
    let isShowAdvancedFilter = ref(false);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const userName = computed(() => store.getters['account/userName']);
    const userInfo = computed(() => store.getters['account/userInfo']);
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    const fileTypeOptions = computed(() => {
      let types = defaultFileTypeOptions;
      if (export_type.value === 'report' || export_type.value === 'report3.2') {
        types = defaultReportFileTypeOptions;
      }
      return types;
    });
    const categoryCount = computed(() => {
      return (
        filter.category.length +
        filter.subCategory.reduce((prev, curr) => {
          return prev + curr.category.length;
        }, 0)
      );
    });

    let loading = ref(true);

    let file_name = ref();
    let export_type = ref('report3.2');
    let export_file_type = ref('pdf');

    let currentPage = ref(1);
    let totalPage = ref();
    let totalRecords = ref();
    let exportHistory = ref([]);

    let activeInterval = ref();

    // filter for export file
    let filter = reactive({
      category: [],
      subCategory: [],
      source: [],
      ownMedia: [],
      messageType: [],
      sentiment: [],
      contentType: [],
      time: {
        sinceDate: moment().subtract(1, 'months').toISOString(),
        untilDate: moment().toISOString(),
      },
    });

    const exportTypeOptions = defaultExportTypeOptions;
    // const fileTypeOptions = defaultFileTypeOptions;

    const filteredExportTypeOptions = computed(() => {
      let result = [];
      if (exportTypeOptions) {
        for (let type of exportTypeOptions) {
          if (type.value === 'raw') {
            if (userInfo.value.id !== '61e53d2e08f1d127489a051d') {
              result.push(type);
            }
          } else {
            result.push(type);
          }
        }
        return result;
      }
      return [];
    });

    // const onDateChange = (dateRange) => {
    //   filter.time.sinceDate = moment(dateRange.fromDate, 'x').toISOString();
    //   filter.time.untilDate = moment(dateRange.untilDate, 'x').toISOString();
    // };

    const onChangeMessageType = (msgTypeList) => {
      filter.messageType = [...msgTypeList];
    };

    const onChangeSentiment = (sentimentList) => {
      filter.sentiment = [...sentimentList];
    };

    const onChangeContentType = (contentTypeList) => {
      filter.contentType = [...contentTypeList];
    };

    const onChangeCategory = (obj) => {
      filter.category = [...obj.category];
      filter.subCategory = [...obj.subCategory];
    };

    const onChangeSource = (sourceList) => {
      filter.source = [...sourceList];
    };

    const onChangeMedia = (mediaList) => {
      filter.ownMedia = [...mediaList];
    };

    const exportFile = async () => {
      // console.log('exportFile', filter);
      setDateFilter();
      if (export_type.value === 'report3.2') {
        await api
          .newExport32(filter, export_file_type.value, file_name.value)
          .catch(() => {});
      } else {
        await api
          .newExport(filter, export_file_type.value, file_name.value)
          .catch(() => {});
      }
      getExportStatus();
      clearFilter();
    };

    const setDateFilter = () => {
      const { sinceDate, untilDate } = filterCriteria.value.time;
      filter.time.sinceDate = sinceDate;
      filter.time.untilDate = untilDate;
    };

    const reload = (pageNo) => {
      currentPage.value = pageNo;
      getExportStatus();
    };

    const getExportStatus = async () => {
      const result = await api
        .getExportStatus(userName.value, currentPage.value)
        .catch(() => {});
      if (result && result.message) {
        const { numPages, numTotalRecords, records } = result.message;
        totalPage.value = numPages;
        totalRecords.value = numTotalRecords;
        exportHistory.value = records;
      }
    };

    const exportHistoryData = computed(() => {
      let result = [];
      if (exportHistory && exportHistory.value) {
        for (let rec of exportHistory.value) {
          rec['key'] = rec._id;
          result.push(rec);
        }
        return result;
      }
      return [];
    });

    const clearFilter = () => {
      file_name.value = '';
      // export_type.value = null;
      // export_file_type.value = null;
    };

    const disabledExportButton = computed(() => {
      return (
        export_type.value === null ||
        export_type.value === undefined ||
        export_file_type.value === null ||
        export_file_type.value === undefined
      );
    });

    const isGenerating = computed(() => {
      return exportHistory.value.some(
        (ele) =>
          ele.exported_status === 'new' || ele.exported_status === 'generating'
      );
    });

    watch(export_type, (value) => {
      let fileType = 'csv';
      if (value === 'report' || value === 'report3.2') {
        fileType = 'pdf';
      }
      export_file_type.value = fileType;
    });

    onMounted(async () => {
      loading.value = true;
      await getExportStatus();
      loading.value = false;
      activeInterval.value = setInterval(() => {
        getExportStatus();
      }, 30000);
    });

    onBeforeUnmount(() => {
      if (activeInterval.value) {
        clearInterval(activeInterval.value);
      }
    });
    onUnmounted(() => {
      clearInterval(activeInterval.value);
    });

    const DateRangePickerButtonRef = ref();
    const onScroll = () => {
      if (typeof DateRangePickerButtonRef.value !== 'undefined') {
        DateRangePickerButtonRef.value.closeDateRangePicker();
      }
    };

    let activeKey = ref('1');

    return {
      loading,
      isShowAdvancedFilter,
      // onDateChange,
      filteredExportTypeOptions,
      fileTypeOptions,
      filter,
      exportFile,
      currentPage,
      totalRecords,
      exportHistoryData,
      file_name,
      export_type,
      export_file_type,
      onChangeCategory,
      onChangeSource,
      onChangeMedia,
      onChangeMessageType,
      onChangeSentiment,
      onChangeContentType,
      disabledExportButton,
      isGenerating,
      getExportStatus,
      reload,
      onScroll,
      DateRangePickerButtonRef,
      activeKey,
      isMobileScreen,
      categoryCount,
      helper,
    };
  },
};
</script>

<style lang="scss" scoped>
.export-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .page-content {
    overflow: auto;
    padding: 30px 20px;
    // min-height: 715px;

    .content-wrapper {
      position: relative;

      .export-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0px -12.5px;

        .filter-sidebar,
        .export-table {
          .detail-card {
            border: 1px solid rgba(244, 245, 247, 0.8);
            box-sizing: border-box;
            border-radius: 10px;

            .filter-content {
              .export-details,
              .filter-details {
                .title {
                  font-weight: 500;
                  font-size: 16px;
                  // margin-left: 0.5rem;
                }
                i {
                  color: #3371ff;
                  margin-right: 10px;
                }

                .detail-container,
                .filter-container {
                  margin-top: 1.3rem;

                  .filter {
                    margin-bottom: 18px;
                    .detail-head {
                      font-weight: 500;
                      margin-bottom: 5px;
                    }
                    .detail-input {
                      // margin-top: 5px;
                      height: 42px;
                      border: 1px solid #e3e6ef;
                      box-sizing: border-box;
                      border-radius: 4px;
                    }

                    .detail-select {
                      // margin-top: 5px;

                      .ant-select-selector {
                        border: 1px solid #e3e6ef;
                        border-radius: 4px;
                        min-height: 42px !important;
                        .ant-select-selection-search input {
                          height: 42px;
                        }
                        .ant-select-selection-placeholder {
                          line-height: 40px !important;
                        }
                      }

                      .custom-export {
                        .ant-checkbox-group {
                          padding: 10px;
                        }
                      }
                    }

                    .drp-wrap {
                      .custom-drp {
                        height: 42px;
                      }
                    }

                    .location-drb .ant-calendar-picker {
                      // max-width: 280px;
                      max-width: 100%;
                    }
                    // .ant-select {
                    //   .ant-select-selector {
                    //     min-height: 42px;
                    //     border-radius: 4px;
                    //   }
                    // }
                  }
                }

                .more-filter {
                  cursor: pointer;
                  margin-bottom: 16px;
                  margin-top: 8px;
                  color: #097cff;
                  font-weight: 500;
                }
              }
            }

            .filter-footer {
              display: flex;
              justify-content: flex-end;

              .btn-export {
                color: #fff;
                background: #3371ff;
              }

              .btn-export-mobile {
                width: 100%;
              }
            }
          }
        }

        .date-range-button .date-range-btn-container .ant-calendar-picker {
          .ant-calendar-picker-input {
            box-shadow: none;
          }
        }
      }
    }

    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: center;
    }
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';
.text-muted {
  color: $gray-solid;
}

.export-page {
  color: $gray-800;
}

:deep(.detail-card, .ant-card-head, .title) {
  color: $gray-800;
}

.location-drb {
  :deep(.ant-calendar-picker) {
    width: 100% !important;
  }
}
</style>
